import React from 'react';
import { getOverrideProps } from '@aws-amplify/ui-react/internal';
import { Flex, Text } from '@aws-amplify/ui-react';
export default function NavbarItem(props) {
  const { children, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="8px 8px 8px 8px"
      style={{ cursor: 'pointer' }}
      {...rest}
      {...getOverrideProps(overrides, 'NavbarItem')}
    >
      <Text
        style={{ cursor: 'pointer' }}
        fontFamily="Alphasans"
        fontSize="16px"
        fontWeight="700"
        color="#1C1260"
        lineHeight="24px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={children}
        {...getOverrideProps(overrides, 'Why Alphalitical?')}
      ></Text>
    </Flex>
  );
}
