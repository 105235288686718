import React from 'react';
import { View } from '@aws-amplify/ui-react';
import styled from 'styled-components';

const Navbar = ({ children }) => {
  const [scrolled, setScrolled] = React.useState(false);

  const changeScrolled = () => {
    if (window.scrollY >= 60) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    changeScrolled();
    window.addEventListener('scroll', changeScrolled);
  });

  return (
    <View width="100vw" height="80px" position="fixed" padding="0px 0px 0px 0px" style={{ zIndex: '99' }}>
      <InnerNavbar scrolled={scrolled}>{children}</InnerNavbar>
    </View>
  );
};

const InnerNavbar = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.scrolled ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')};
  box-shadow: ${(props) =>
    props.scrolled
      ? '0px 4px 72px rgba(0.10938931256532669, 0.07140691578388214, 0.3752661347389221, 0.20000000298023224)'
      : '0'};
  transition: background-color 0.1s ease-in;
`;

export default Navbar;
