import '@/styles/global.css';
import type { AppProps } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import { Navbar, NavbarMenu } from '../modules/Navbar';
import { Flex } from '@aws-amplify/ui-react';
import Link from 'next/link';
import Image from 'next/image';
import Head from 'next/head';
import Footer from 'modules/footer/Footer';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Alphalitical</title>
      </Head>
      <Navbar>
        <Flex>
          <Link href="/">
            <Image src="/logo-color.svg" alt="Logo" width={230} height={40} />
          </Link>
          <NavbarMenu />
        </Flex>
        {/*
        <View>
          <Flex gap={24}>
            <Link href={process.env.NEXT_PUBLIC_LOGIN_URL}>
              <NavbarItem>Sign In</NavbarItem>
            </Link>
            <a href={process.env.NEXT_PUBLIC_LOGIN_URL + '/register'} style={{ all: 'unset' }}>
              <ButtonSignUp>Sign Up, Free!</ButtonSignUp>
            </a>
          </Flex>
        </View>
      */}
      </Navbar>
      <main>
        <Component {...pageProps} />
      </main>

      <Footer />
    </>
  );
}

export default MyApp;
