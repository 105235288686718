import styled from 'styled-components';
import { Text, TextProps } from '@aws-amplify/ui-react';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

interface TypographyProps {
  margin?: string | number;
  color?: string;
  textAlign?: string;
  fontWeight?: string;
  children?: React.ReactNode;
}

interface HeadingProps
  extends TypographyProps,
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {}

export const H1: FC<HeadingProps> = styled.h1`
  font-size: 48px;
  font-weight: 800;
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: ${(props) => (props.color ? props.color : 'rgba(28,18,96,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  line-height: 56px;
`;
export const H2: FC<HeadingProps> = styled.h2`
  font-size: 32px;
  font-weight: 800;
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: ${(props) => (props.color ? props.color : 'rgba(28,18,96,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  line-height: 48px;
`;

export const H3: FC<HeadingProps> = styled.h3`
  font-size: 24px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: ${(props) => (props.color ? props.color : 'rgba(28,18,96,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  line-height: 32px;
`;

export const H5: FC<HeadingProps> = styled.h5`
  font-size: 18px;
  font-weight: 800;
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: ${(props) => (props.color ? props.color : 'rgba(28,18,96,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  line-height: 27px;
`;

export const H6: FC<HeadingProps> = styled.h6`
  font-size: 16px;
  font-weight: 700;
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: ${(props) => (props.color ? props.color : 'rgba(28,18,96,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  line-height: 24px;
`;

interface TypographyTextProps extends TextProps {
  margin?: string | number;
  color?: string;
  textAlign?: string;
  children?: React.ReactNode;
}

export const Title3: FC<TypographyTextProps> = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  white-space: break-spaces;
  color: ${(props) => (props.color ? props.color : '#1C1260;')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  margin: ${(props) => (props.margin ? props.margin : '20px 0px')};
  line-height: 24px;
`;

export const Paragraph: FC<TypographyTextProps> = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  white-space: break-spaces;
  color: ${(props) => (props.color ? props.color : 'rgba(131,142,159,1)')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  margin: ${(props) => (props.margin ? props.margin : '20px 0px')};
  line-height: 26px;
`;
