import React from 'react';
import { View, Flex } from '@aws-amplify/ui-react';
import NavbarItem from './NavbarItem';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';

const NavItem = styled.span`
  transition: border-bottom 0.1s linear;
  border-bottom: 2px transparent #ff9500;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #ff9500;
  }
  &.active {
    border-bottom: 2px solid #ff9500;
  }
`;

const NavbarMenu = () => {
  const router = useRouter();
  const pathname = router.pathname;

  return (
    <View>
      <Flex>
        <Link href="/">
          <NavItem className={pathname === '/' && 'active'}>
            <NavbarItem>Home</NavbarItem>
          </NavItem>
        </Link>
        <Link href="/#features">
          <NavItem>
            <NavbarItem>Features</NavbarItem>
          </NavItem>
        </Link>
        <Link href="/#whyAlphalitical">
          <NavItem>
            <NavbarItem>Why Alphalitical?</NavbarItem>
          </NavItem>
        </Link>
        {process.env.NEXT_PUBLIC_WITH_SEARCH_FUNCTION === 'true' && (
          <Link href="/search">
            <NavItem className={pathname.includes('search') && 'active'}>
              <NavbarItem>Search</NavbarItem>
            </NavItem>
          </Link>
        )}
      </Flex>
    </View>
  );
};

export default NavbarMenu;
