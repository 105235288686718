import React from 'react';
import { Flex, Link as LinkAmplify, View } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import Image from 'next/image';
import { Paragraph } from '@/components/Typography/Typography';

const Link = styled(LinkAmplify)`
  color: #ffffff;
  font-size: 16px;
  white-space: break-spaces;
  text-align: left;
  margin: 20px 0px;
  line-height: 26px;
`;

const Footer = () => {
  return (
    <Flex justifyContent="center" gap="0px" bottom={0}>
      <View width="100%" height="100%" backgroundColor="#101820" padding={'32px 100px 12px 100px'} position="relative">
        <Flex direction="row">
          <img src="/logo.svg" alt="AlphaLogoWhite" />
          <Paragraph style={{ borderLeft: '1px solid white' }} paddingLeft="15px" fontSize={14} color="#ffffff">
            Decoding data library into predictive analysis that create impactful business strategies.
          </Paragraph>
        </Flex>
        <Flex>
          <Paragraph fontSize={16} color="#ffffff">
            Jl. Petogogan II No. 32A, Jakarta Selatan, 12160
          </Paragraph>
        </Flex>
        <Flex direction="row" justifyContent="space-between" alignItems="center" marginTop={24}>
          <Flex direction="row">
            <Link href="/privacy-policy" color="#ffffff">
              Privacy Policy
            </Link>
            <Link href="/terms-and-condition" color="#ffffff">{`Terms & Condition`}</Link>
            <Link href="/about-us" color="#ffffff">
              About us
            </Link>
            <Link href="/contact-us" color="#ffffff">
              Contact
            </Link>
            <Link href="/careers" color="#ffffff">
              Careers
            </Link>
          </Flex>
          <Flex direction="row" alignItems="center" gap={10}>
            <Flex direction="row" alignItems="center">
              <a
                href="https://www.facebook.com/Alphalitical-100205812333727/?ref=pages_you_manage"
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/images/socmed/facebook.svg" alt="Facebook" width={24} height={24} />
              </a>
              <a href="https://twitter.com/AlphaliticalDev" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/twitter.svg" alt="Twitter" width={24} height={24} />
              </a>
              <a href="https://www.instagram.com/alphalitical/" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/instagram.svg" alt="Instagram" width={24} height={24} />
              </a>
              <a href="https://www.linkedin.com/company/alphalitical/" target="_blank" rel="noreferrer">
                <Image src="/images/socmed/linkedin.svg" alt="LinkedIn" width={24} height={24} />
              </a>
            </Flex>
          </Flex>
        </Flex>
        <View border="0.5px solid #63666A" marginTop={32} marginBottom={32} />
        <Paragraph textAlign="center" margin="0">
          © Copyright 2022 by Alphalitical.
        </Paragraph>
      </View>
    </Flex>
  );
};

export default Footer;
